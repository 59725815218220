/* eslint-disable prettier/prettier */
export const iconName = 'measures_ventilation'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'circle',
    color: 'base',
    attrs: {
      cx: '32.18',
      cy: '30.96',
      r: '21.87',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M37.09,31.93h-.48l.12-2c3,.17,7.87-1.6,8.38-3.07.76-2.22-.34-5-1.77-6.78-.93-1.17-1.67-1.47-1.84-1.46,0,0,0,.13-.09.44a11.38,11.38,0,0,1-5.81,8.51l-.87-1.84.43.92-.44-.92a9.33,9.33,0,0,0,4.68-7,2.31,2.31,0,0,1,1.49-2.08c1-.32,2.2.18,3.41,1.41,2.2,2.24,3.84,6.2,2.73,9.43C46.08,30.25,40.29,31.93,37.09,31.93Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M34.09,48.14a8.66,8.66,0,0,1-6-2.11c-2.23-2.14-1.1-8.63.36-11.33l1.79,1c-1.43,2.66-1.86,7.81-.74,8.9,1.68,1.62,4.67,1.8,6.89,1.25,1.45-.35,2-.9,2.1-1.06,0,0-.1-.09-.36-.26a11.36,11.36,0,0,1-5.27-8.86l2,0a9.31,9.31,0,0,0,4.35,7.18A2.31,2.31,0,0,1,40.51,45c-.13,1-1.09,1.92-2.72,2.5A11.35,11.35,0,0,1,34.09,48.14Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M16,31.78a1.87,1.87,0,0,1-1.12-.34c-.86-.6-1.19-1.87-.94-3.57.46-3.11,2.62-6.81,5.86-7.88,2.95-1,8.14,3.07,9.84,5.62L28,26.74c-1.68-2.51-6-5.3-7.51-4.81-2.22.73-3.78,3.29-4.33,5.5a3.39,3.39,0,0,0-.05,2.35s.13-.05.4-.2a11.36,11.36,0,0,1,10.29-.51l-.93,1.81a9.28,9.28,0,0,0-8.38.49A3,3,0,0,1,16,31.78Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M31.54,36.79a5.81,5.81,0,0,1-.72,0,6,6,0,1,1,.72,0Zm0-10.25a4.1,4.1,0,1,0,3.22,1.55,4.09,4.09,0,0,0-3.22-1.55Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M32,54a23,23,0,1,1,23-23,23.78,23.78,0,0,1-.49,4.69,1,1,0,0,1-2-.42A21,21,0,1,0,32,52a20.58,20.58,0,0,0,9.36-2.2,1,1,0,0,1,.91,1.83A22.86,22.86,0,0,1,32,54Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M56.5,40a5.86,5.86,0,0,1-4.2-1.74,3.92,3.92,0,0,0-5.53,0,1,1,0,1,1-1.44-1.44A6,6,0,0,1,49.53,35h0a5.94,5.94,0,0,1,4.2,1.74,4,4,0,0,0,5.53,0,1,1,0,0,1,1.44,0,1,1,0,0,1,0,1.44A5.86,5.86,0,0,1,56.5,40Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M56.47,45.14a5.82,5.82,0,0,1-4.18-1.76,3.91,3.91,0,0,0-5.52,0,1,1,0,0,1-1.44,0,1,1,0,0,1,0-1.44,6,6,0,0,1,8.41,0,3.8,3.8,0,0,0,3.76,1,4.1,4.1,0,0,0,1.77-1,1,1,0,0,1,1.44.06,1,1,0,0,1,0,1.39,6.13,6.13,0,0,1-2.64,1.53A5.69,5.69,0,0,1,56.47,45.14Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M56.47,50.45a5.82,5.82,0,0,1-4.18-1.76,3.91,3.91,0,0,0-5.52,0,1,1,0,0,1-1.44,0,1,1,0,0,1,0-1.44,6,6,0,0,1,8.41,0,3.8,3.8,0,0,0,3.76,1,4.16,4.16,0,0,0,1.77-1,1,1,0,0,1,1.44,0,1,1,0,0,1,0,1.43,6.13,6.13,0,0,1-2.64,1.53A5.69,5.69,0,0,1,56.47,50.45Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresVentilation = define
