<script>
import PMultiColorIcon from '@/components/common/PMultiColorIcon.vue'
import { mergeData } from 'vue-functional-data-merge'

const IconKeys = [
  'ventilation',
  'disinfect',
  'customer',
  'distance',
  'staff',
  'restroom',
  'certification',
]

const ColorSet = {
  shops: {
    main: '#1D8DD5',
  },
  schools: {
    main: '#E38314',
  },
  hospitals: {
    main: '#58B6D0',
  },
}
const ColorSetKeys = Object.keys(ColorSet)

export default {
  name: 'PShopMeasuresIcon',
  functional: true,
  props: {
    icon: {
      type: String,
      default: 'ventilation',
      validator: (val) => IconKeys.includes(val),
    },
    small: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    portalId: {
      type: String,
      default: 'shops',
      validator: (val) => ColorSetKeys.includes(val),
    },
  },
  render(h, { props, data }) {
    const tag = data.tag || 'span'

    const computedClass = ['p-shopMeasuresIcon']
    const colorSet = ColorSet[props.portalId]

    if (props.small) computedClass.push('p-shopMeasuresIcon--small')
    else if (props.mobile) computedClass.push('p-shopMeasuresIcon--mobile')
    if (props.active === true) computedClass.push('p-shopMeasuresIcon--active')
    if (props.active === false)
      computedClass.push('p-shopMeasuresIcon--inactive')

    const componentData = mergeData(data, {
      class: computedClass,
    })

    return h(tag, componentData, [
      h(PMultiColorIcon, {
        props: {
          iconName: props.icon,
          colorSet,
        },
        attrs: {
          class: 'p-shopMeasuresIcon__img',
        },
      }),
    ])
  },
}

export const ShopMeasuresIconColorSet = ColorSet
</script>
