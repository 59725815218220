/* eslint-disable prettier/prettier */
export const iconName = 'measures_disinfect'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M29.67,19.34l15.18,0a6.07,6.07,0,0,1,6.08,6.07L51,55.24h0l-27.33,0h0L23.6,25.42A6.08,6.08,0,0,1,29.67,19.34Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M23.65,56.3a1,1,0,0,1-1-1l-.05-29.78a7.18,7.18,0,0,1,7.16-7.19l15,0h0A7.2,7.2,0,0,1,52,25.46l0,29.78a1,1,0,0,1-1,1h0l-27.33,0Zm21.12-36h0l-15,0a5.13,5.13,0,0,0-5.13,5.15h0l0,28.76,25.3,0-.05-28.77a5.15,5.15,0,0,0-5.14-5.14Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M18.87,16.76h0a1,1,0,0,1-1-1h0V14.61A4.14,4.14,0,0,1,22,10.49h0l9.19,0a1,1,0,1,1,0,2h0l-9.19,0h0a2.11,2.11,0,0,0-2.1,2.1v1.13a1,1,0,0,1-1,1Z',
    },
  },
  {
    tag: 'rect',
    color: 'base',
    attrs: {
      x: '32.19',
      y: '9.01',
      width: '10.21',
      height: '5.39',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M42.4,15.42H32.19a1,1,0,0,1-1-1V9a1,1,0,0,1,1-1H42.4a1,1,0,0,1,1,1v5.4A1,1,0,0,1,42.4,15.42Zm-9.2-2h8.19V10H33.2Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M37.3,19.31a1,1,0,0,1-1-1V14.91a1,1,0,0,1,2,0v3.38a1,1,0,0,1-1,1Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M15.31,22.32l-.23,0a.86.86,0,0,1-.21-.07.75.75,0,0,1-.2-.1.65.65,0,0,1-.18-.15,1.22,1.22,0,0,1-.15-.17,2,2,0,0,1-.11-.2l-.06-.22a.86.86,0,0,1,0-.23.82.82,0,0,1,0-.22,1,1,0,0,1,.06-.23c0-.06.07-.13.11-.2l.15-.17a.86.86,0,0,1,.18-.14.78.78,0,0,1,.2-.11l.21-.07a1.22,1.22,0,0,1,1.06.32,1.15,1.15,0,0,1,.14.17l.11.2a.86.86,0,0,1,.06.23.78.78,0,0,1,0,.22,1.19,1.19,0,0,1-.34.82A1.17,1.17,0,0,1,15.31,22.32Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M12.37,25.31a1.17,1.17,0,0,1-.28-1.62h0a1.17,1.17,0,0,1,1.63-.28h0A1.17,1.17,0,0,1,14,25h0a1.17,1.17,0,0,1-1,.48h0A1.09,1.09,0,0,1,12.37,25.31Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M10.78,28.74a1.17,1.17,0,0,1-1.17-1.17.91.91,0,0,1,0-.23.83.83,0,0,1,.06-.21,1.28,1.28,0,0,1,.11-.2,1.33,1.33,0,0,1,.14-.19A1.21,1.21,0,0,1,11,26.43l.22.07.19.1a.86.86,0,0,1,.18.14l.15.19a1.28,1.28,0,0,1,.11.2.73.73,0,0,1,.06.21,1.3,1.3,0,0,1-.06.68l-.11.2a1.22,1.22,0,0,1-.15.17A1.18,1.18,0,0,1,10.78,28.74Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M12.17,19.77l-.22,0-.23-.06a2,2,0,0,1-.19-.12.79.79,0,0,1-.18-.14A1.17,1.17,0,0,1,11,18.6a1.15,1.15,0,0,1,.34-.82,1.2,1.2,0,0,1,1.65,0,1.17,1.17,0,0,1-.78,2Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M7.64,21a1.17,1.17,0,0,1,.48-1.57h0a1.17,1.17,0,0,1,1.58.49h0a1.16,1.16,0,0,1-.49,1.57h0a1.15,1.15,0,0,1-.55.14h0A1.16,1.16,0,0,1,7.64,21Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M5.17,23.49a1.14,1.14,0,0,1-.82-.35A.8.8,0,0,1,4.2,23a1.28,1.28,0,0,1-.11-.2A1.69,1.69,0,0,1,4,22.32a1.21,1.21,0,0,1,.34-.82.65.65,0,0,1,.18-.15,1.24,1.24,0,0,1,.2-.1l.22-.07a1.19,1.19,0,0,1,1,.32,1.17,1.17,0,0,1,.34.82,1.79,1.79,0,0,1,0,.23.81.81,0,0,1-.07.22,1.24,1.24,0,0,1-.1.2,1.2,1.2,0,0,1-1,.52Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M18.75,23.86a.85.85,0,0,1-.23,0,.79.79,0,0,1-.22-.06l-.2-.11a1.17,1.17,0,0,1-.51-1,2,2,0,0,1,0-.23,1.72,1.72,0,0,1,.07-.21,1.24,1.24,0,0,1,.1-.2.65.65,0,0,1,.15-.18,1.13,1.13,0,0,1,1-.32.86.86,0,0,1,.23.06l.2.11a1.22,1.22,0,0,1,.17.15.65.65,0,0,1,.15.18,1.24,1.24,0,0,1,.1.2.86.86,0,0,1,.07.21,1,1,0,0,1,0,.24,1.17,1.17,0,0,1-.34.82A1.19,1.19,0,0,1,18.75,23.86Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M18.71,27.66a1.18,1.18,0,0,1-1.16-1.17h0a1.18,1.18,0,0,1,1.18-1.16h0a1.17,1.17,0,0,1,1.15,1.18h0a1.16,1.16,0,0,1-1.16,1.16h0Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M18.69,31.46a1.16,1.16,0,0,1-1.17-1.15h0a1.17,1.17,0,0,1,1.18-1.16l.22,0a.79.79,0,0,1,.22.06l.2.12.17.14a1.13,1.13,0,0,1,.34.82,1.15,1.15,0,0,1-1.15,1.16Z',
    },
  },
  {
    tag: 'rect',
    color: 'main',
    attrs: {
      x: '24.68',
      y: '31.85',
      width: '25.22',
      height: '12.71',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresDisinfect = define
