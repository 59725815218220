/* eslint-disable prettier/prettier */
export const iconName = 'measures_distance'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'circle',
    color: 'base',
    attrs: {
      cx: '49.54',
      cy: '28.3',
      r: '3.76',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M49.54,33a4.75,4.75,0,1,1,4.75-4.74A4.75,4.75,0,0,1,49.54,33Zm0-7.52a2.78,2.78,0,1,0,2.77,2.78,2.78,2.78,0,0,0-2.77-2.78Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M41.45,48.92v-7.2c0-3.21,2.1-5.81,4.7-5.81h6.78c2.6,0,4.7,2.6,4.7,5.81v7.2',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M57.63,49.91a1,1,0,0,1-1-1h0v-7.2c0-2.66-1.66-4.83-3.71-4.83H46.15c-2,0-3.71,2.17-3.71,4.83v7.2a1,1,0,0,1-2,0v-7.2c0-3.75,2.55-6.8,5.68-6.8h6.78c3.13,0,5.68,3,5.68,6.8v7.2A1,1,0,0,1,57.63,49.91Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M53.05,14H12.28a1,1,0,0,1,0-2H53.05a1,1,0,0,1,0,2Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M15.12,16.81a1,1,0,0,1-.61-.21l-3.69-2.92a1,1,0,0,1-.16-1.39l.14-.15,3.54-2.91a1,1,0,0,1,1.25,1.52L13,12.89l2.73,2.16a1,1,0,0,1,.16,1.39,1,1,0,0,1-.77.37Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M49.52,16.81a1,1,0,0,1-.63-1.75l2.59-2.14-2.73-2.16A1,1,0,1,1,50,9.21l3.68,2.92a1,1,0,0,1,.16,1.39l-.14.15-3.53,2.92A1,1,0,0,1,49.52,16.81Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M32.23,55.71a1,1,0,0,1-1-1h0V20.2a1,1,0,0,1,2,0h0V54.73A1,1,0,0,1,32.23,55.71Z',
    },
  },
  {
    tag: 'circle',
    color: 'base',
    attrs: {
      cx: '14.46',
      cy: '28.3',
      r: '3.76',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M14.46,33a4.75,4.75,0,1,1,4.75-4.74A4.75,4.75,0,0,1,14.46,33Zm0-7.52a2.78,2.78,0,1,0,2.77,2.78,2.78,2.78,0,0,0-2.77-2.78Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M6.37,48.92v-7.2c0-3.21,2.1-5.81,4.7-5.81h6.78c2.6,0,4.7,2.6,4.7,5.81v7.2',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M22.55,49.91a1,1,0,0,1-1-1h0v-7.2c0-2.66-1.67-4.83-3.71-4.83H11.07c-2.05,0-3.71,2.17-3.71,4.83v7.2a1,1,0,0,1-2,0v-7.2c0-3.75,2.55-6.8,5.69-6.8h6.78c3.13,0,5.68,3,5.68,6.8v7.2A1,1,0,0,1,22.55,49.91Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M26.93,51.16H2a1,1,0,1,1,0-2H26.93a1,1,0,0,1,0,2Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M62,51.16H37.07a1,1,0,0,1,0-2H62a1,1,0,0,1,0,2Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresDistance = define
