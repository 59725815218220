/* eslint-disable prettier/prettier */
export const iconName = 'measures_staff'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M50.35,34.89c0,10.74-15.52,14.42-18,14.94a1.56,1.56,0,0,1-.68,0c-2.49-.52-18-4.2-18-14.94V22.71a1.66,1.66,0,0,1,1.1-1.56l16.68-6.07a1.6,1.6,0,0,1,1.14,0l16.68,6.06a1.67,1.67,0,0,1,1.1,1.57Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M32,50.85a2.48,2.48,0,0,1-.54-.06c-3.13-.66-18.79-4.51-18.79-15.9V22.71a2.66,2.66,0,0,1,1.74-2.48L31.1,14.16a2.65,2.65,0,0,1,1.8,0l16.69,6.06a2.68,2.68,0,0,1,1.74,2.48V34.89h0c0,11.39-15.66,15.24-18.79,15.9A2.48,2.48,0,0,1,32,50.85ZM32,16a.59.59,0,0,0-.23.05L15.08,22.07a.69.69,0,0,0-.45.64V34.89c0,10.08,15.47,13.62,17.23,14a.61.61,0,0,0,.27,0c1.77-.37,17.24-3.9,17.24-14V22.71a.69.69,0,0,0-.45-.65L32.24,16A.67.67,0,0,0,32,16Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M52,39.2H49.81a1,1,0,1,1,0-2H52a4,4,0,0,0,4-4v-5a4,4,0,0,0-4-4H50.35a1,1,0,0,1,0-2H52a6,6,0,0,1,6,6v5A6,6,0,0,1,52,39.2Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M14.19,39.2H12a6,6,0,0,1-6-6v-5a6,6,0,0,1,6-6h1.67a1,1,0,0,1,0,2H12a4,4,0,0,0-4,4v5a4,4,0,0,0,4,4h2.21a1,1,0,1,1,0,2Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M40.34,30.86H23.66a1,1,0,1,1,0-1.95H40.34a1,1,0,1,1,0,1.95Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M40.34,37.53H23.66a1,1,0,1,1,0-2H40.34a1,1,0,1,1,0,2Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresStaff = define
