/* eslint-disable prettier/prettier */
export const iconName = 'measures_customer'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'rect',
    color: 'base',
    attrs: {
      x: '21.71',
      y: '32.08',
      width: '9.66',
      height: '9.72',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M31.38,42.84H21.72a1,1,0,0,1-1-1h0V32.08a1,1,0,0,1,1-1h9.67a1,1,0,0,1,1,1h0V41.8a1,1,0,0,1-1,1Zm-8.62-2.09h7.57V33.13H22.76Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M47.69,15H11.83A3.68,3.68,0,0,0,8,18.59V32.4a3.77,3.77,0,0,0,3.78,3.73V56.07h12.5V35.6l23.45-1.67a1.24,1.24,0,0,0,1.15-1.24V16.28A1.24,1.24,0,0,0,47.69,15Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M24.32,57.11H11.82a1,1,0,0,1-1-1h0v-19A4.8,4.8,0,0,1,7,32.4V18.88A4.7,4.7,0,0,1,11.5,14H47.69A2.28,2.28,0,0,1,50,16.28V32.69A2.3,2.3,0,0,1,47.85,35L25.37,36.58V56.07a1,1,0,0,1-1,1ZM12.86,55H23.28V35.6a1,1,0,0,1,1-1L47.7,32.88a.19.19,0,0,0,.18-.19V16.28a.19.19,0,0,0-.19-.19H11.83a2.62,2.62,0,0,0-2.75,2.49c0,.1,0,.2,0,.3V32.4a2.71,2.71,0,0,0,2.73,2.69,1,1,0,0,1,1,1h0Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M41.37,34.91a1,1,0,0,1-1-1h0V15.1a1,1,0,0,1,2.09,0V33.87A1,1,0,0,1,41.37,34.91Z',
    },
  },
  {
    tag: 'rect',
    color: 'main',
    attrs: {
      x: '14.77',
      y: '20.98',
      width: '12.6',
      height: '6.72',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M59.11,32.4a1,1,0,0,1-1-1,1.05,1.05,0,0,1,.32-.75,9.1,9.1,0,0,0-.11-12.71,1,1,0,0,1,0-1.47,1,1,0,0,1,1.45-.06,11.18,11.18,0,0,1,.14,15.74A1,1,0,0,1,59.11,32.4Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M53.74,28.8A1,1,0,0,1,53.06,27a3.66,3.66,0,0,0-.08-5.4,1,1,0,0,1,1.28-1.65A5.75,5.75,0,0,1,54.93,28a6.17,6.17,0,0,1-.5.53A1.08,1.08,0,0,1,53.74,28.8Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresCustomer = define
