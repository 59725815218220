<template>
  <div class="p-youtubePlayer" :class="computedClass">
    <div
      v-if="!normalizedPlayed"
      class="p-youtubePlayer__thumbnail"
      :style="`background-image: url(http://img.youtube.com/vi/${videoId}/mqdefault.jpg)`"
      @click="handleClickThumbnail"
    />
    <iframe
      ref="iframe"
      class="p-youtubePlayer__embed"
      :src="`https://www.youtube.com/embed/${videoId}?enablejsapi=1`"
      title="YouTube video player"
      frameborder="0"
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  </div>
</template>

<script>
const sizes = [
  'full',
  '1x1',
  'square',
  '16x16',
  '24x24',
  '32x32',
  '48x48',
  '64x64',
  '96x96',
  '128x128',
  '5p4',
  '4p3',
  '3p2',
  '5p3',
  '5p2',
  '16p9',
  '2p1',
  '3p1',
  '4p5',
  '3p4',
  '2p3',
  '3p5',
  '2p5',
  '9p16',
  '1p2',
  '1p3',
]

export default {
  name: 'PYouTubePlayer',
  model: {
    prop: 'played',
    event: 'change',
  },
  props: {
    videoId: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: (value) => sizes.includes(value),
    },
    played: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      localPlayed: false,
    }
  },
  computed: {
    computedClass() {
      const result = []

      if (this.size) result.push(`p-youtubePlayer--${this.size}`)

      return result
    },
    iframeEl() {
      return this.$refs.iframe
    },
    normalizedPlayed() {
      return typeof this.played !== 'undefined' ? this.played : this.localPlayed
    },
  },
  watch: {
    played(next) {
      if (next) {
        this.iframeEl.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          '*'
        )
      } else {
        this.iframeEl.contentWindow.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*'
        )
      }
    },
  },
  methods: {
    handleClickThumbnail() {
      if (typeof this.played !== 'undefined') {
        this.$emit('change', true)
      } else {
        this.localPlayed = true
      }
    },
  },
}
</script>
