/* eslint-disable prettier/prettier */
export const iconName = 'measures_certification'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M21.23,38.56,14.88,50.28l7.87-1.73,2.56,7.7,7.11-13.18',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M25.31,57.12h-.08a.86.86,0,0,1-.75-.59l-2.31-7-7.1,1.55a.86.86,0,0,1-.89-.33.87.87,0,0,1-.07-.94l6.35-11.72a.89.89,0,0,1,1.19-.35A.88.88,0,0,1,22,39L16.56,49l6-1.32.19,0a.9.9,0,0,1,.83.6L25.5,54l6.15-11.4a.87.87,0,0,1,1.18-.36h0a.88.88,0,0,1,.36,1.19L26.08,56.66A.88.88,0,0,1,25.31,57.12Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M43.4,39,50,50.08l-7.61-1.57-2.48,7.35L32.68,43.11',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M39.91,56.74a.92.92,0,0,1-.76-.45L31.92,43.54a.87.87,0,0,1,.33-1.19h0a.87.87,0,0,1,1.19.32v0l6.27,11,1.85-5.48a.87.87,0,0,1,1-.58l5.67,1.17-5.59-9.4a.87.87,0,0,1,.3-1.2h0a.88.88,0,0,1,1.2.29h0l6.6,11.1A.88.88,0,0,1,50,51l-.18,0L43,49.53l-2.23,6.6a.89.89,0,0,1-.75.6Z',
    },
  },
  {
    tag: 'circle',
    color: 'base',
    attrs: {
      cx: '32.42',
      cy: '25.2',
      r: '18.2',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M32.42,8.75a16.46,16.46,0,1,0,11.63,4.82h0A16.34,16.34,0,0,0,32.42,8.75m0-1.75a18.2,18.2,0,1,1-18.2,18.2A18.2,18.2,0,0,1,32.42,7Z',
    },
  },
  {
    tag: 'circle',
    color: 'base',
    attrs: {
      cx: '32.42',
      cy: '25.2',
      r: '14.16',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M32.42,12.8A12.41,12.41,0,1,0,44.83,25.21h0A12.43,12.43,0,0,0,32.42,12.8m0-1.75A14.16,14.16,0,1,1,18.26,25.2h0A14.15,14.15,0,0,1,32.42,11.05Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M32,17.37a.5.5,0,0,1,.67-.23.51.51,0,0,1,.23.23l2.06,4.26a.5.5,0,0,0,.39.27l4.78.59a.5.5,0,0,1,.44.55.52.52,0,0,1-.16.31l-3.5,3.19a.49.49,0,0,0-.16.46l.9,4.58a.49.49,0,0,1-.39.59.48.48,0,0,1-.33-.05l-4.24-2.27a.49.49,0,0,0-.47,0l-4.26,2.27a.49.49,0,0,1-.67-.2.48.48,0,0,1-.05-.33L28.1,27a.52.52,0,0,0-.15-.46l-3.5-3.19a.49.49,0,0,1,0-.7.47.47,0,0,1,.31-.16l4.78-.58a.54.54,0,0,0,.39-.28Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M32.41,20.47l-.89,1.83a2.42,2.42,0,0,1-1.89,1.36l-2,.24,1.4,1.27a2.45,2.45,0,0,1,.75,2.27l-.37,1.89,1.84-1a2.44,2.44,0,0,1,2.29,0l1.84,1L35,27.44a2.45,2.45,0,0,1,.75-2.27l1.4-1.27-2-.24A2.42,2.42,0,0,1,33.3,22.3l-.89-1.83m0-3.15a.65.65,0,0,1,.61.38l1.86,3.84a.68.68,0,0,0,.53.38l4.31.52a.67.67,0,0,1,.58.75.68.68,0,0,1-.21.42L37,26.47a.63.63,0,0,0-.21.62l.81,4.13A.66.66,0,0,1,37,32a.7.7,0,0,1-.45-.06L32.73,29.9a.66.66,0,0,0-.64,0L28.25,32a.67.67,0,0,1-.91-.28.62.62,0,0,1-.07-.44l.81-4.13a.67.67,0,0,0-.2-.63l-3.15-2.86a.68.68,0,0,1,0-.95.67.67,0,0,1,.42-.22l4.31-.52a.68.68,0,0,0,.52-.38l1.86-3.85a.69.69,0,0,1,.61-.38Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresCertification = define
