<template>
  <div
    :class="[
      'c-progress',
      { 'c-progress--zero': now <= 0, 'c-progress--large': large },
    ]"
  >
    <div class="c-progress__bar" :style="{ width: percent }" />
    <span
      class="c-progress__value"
      :style="{ width: now > 0 ? percent : '100%' }"
      >{{ showValue ? percent.substr(0, percent.length - 1) : null }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'CProgress',
  props: {
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    range() {
      return this.max - this.min
    },
    now() {
      // return ((this.value - this.min) / this.range) * 100
      return ((this.value - this.min) * 100) / this.range
    },
    percent() {
      return this.now + '%'
    },
  },
}
</script>
