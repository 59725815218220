<script>
import { mergeData } from 'vue-functional-data-merge'
import { measuresVentilation } from '@/assets/modules/icon/js/threeColors/measures_ventilation'
import { measuresDisinfect } from '@/assets/modules/icon/js/threeColors/measures_disinfect'
import { measuresCustomer } from '@/assets/modules/icon/js/threeColors/measures_customer'
import { measuresDistance } from '@/assets/modules/icon/js/threeColors/measures_distance'
import { measuresStaff } from '@/assets/modules/icon/js/threeColors/measures_staff'
import { measuresRestroom } from '@/assets/modules/icon/js/threeColors/measures_restroom'
import { measuresCertification } from '@/assets/modules/icon/js/threeColors/measures_certification'

const Icon = {
  ventilation: measuresVentilation.icon,
  disinfect: measuresDisinfect.icon,
  customer: measuresCustomer.icon,
  distance: measuresDistance.icon,
  staff: measuresStaff.icon,
  restroom: measuresRestroom.icon,
  certification: measuresCertification.icon,
}
const IconKeys = Object.keys(Icon)

export default {
  name: 'PMultiColorIcon',
  functional: true,
  props: {
    iconName: {
      type: String,
      default: 'ventilation',
      validator: (val) => IconKeys.includes(val),
    },
    colorSet: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, data }) {
    const icon = Icon[props.iconName]
    const colorSet = {
      ...icon.color,
      ...props.colorSet,
    }

    const componentData = mergeData(data, {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: `0 0 ${icon.width} ${icon.height}`,
      },
    })

    return h(
      'svg',
      componentData,
      icon.children.map(({ tag, color, attrs }) => {
        return h(tag, {
          attrs: {
            fill: colorSet[color],
            ...attrs,
          },
        })
      })
    )
  },
}
</script>
