/* eslint-disable prettier/prettier */
export const iconName = 'measures_restroom'
export const width = 64
export const height = 64
export const color = {
  base: '#fff',
  line: '#222',
  main: '#1d8dd5',
}
export const children = [
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M40.8,10.93H53V25.35a3,3,0,0,1-3,3H40.8V10.93Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M49.87,29.39H40.8a1,1,0,0,1-1-1h0V10.93a1,1,0,0,1,1-1H53a1,1,0,0,1,1,1h0V25.26A4.14,4.14,0,0,1,49.87,29.39Zm-8-2.06h8a2.08,2.08,0,0,0,2.07-2.07V12H41.83Z',
    },
  },
  {
    tag: 'path',
    color: 'base',
    attrs: {
      d: 'M27.15,33.29h-13v2.6A12.86,12.86,0,0,0,27,48.74h.18V55.5H46.06V33.29Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M46.06,56.53H27.15a1,1,0,0,1-1-1V49.74a13.88,13.88,0,0,1-13-13.85v-2.6a1,1,0,0,1,1-1H46.06a1,1,0,0,1,1,1V55.5a1,1,0,0,1-1,1ZM28.18,54.47H45V34.32H15.16v1.57A11.81,11.81,0,0,0,27,47.7h.18a1,1,0,0,1,1,1h0Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M39.69,33.21a1.06,1.06,0,0,1-.57-.17L19.05,19.63a1,1,0,0,1,1.14-1.72h0L40.26,31.32a1,1,0,0,1-.57,1.89Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M11.31,21.84H5a1,1,0,1,1,0-2.06h6.28a1,1,0,1,1,0,2.06Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M8.17,25a1,1,0,0,1-1-1h0V17.67a1,1,0,1,1,2.06,0h0V24a1,1,0,0,1-1,1Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M16.84,12.2H10.56a1,1,0,0,1,0-2.06h6.28a1,1,0,1,1,0,2.06Z',
    },
  },
  {
    tag: 'path',
    color: 'main',
    attrs: {
      d: 'M13.7,15.34a1,1,0,0,1-1-1V8a1,1,0,0,1,2.06,0v6.28a1,1,0,0,1-1,1Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M52.35,17.59h-11a1,1,0,1,1,0-2.06h11a1,1,0,1,1,0,2.06Z',
    },
  },
  {
    tag: 'path',
    color: 'line',
    attrs: {
      d: 'M27.44,44.6h-.07a10.53,10.53,0,0,1-8.22-4.7,1,1,0,0,1,1.78-1,8.45,8.45,0,0,0,6.59,3.68,1,1,0,0,1,.95,1.11,1,1,0,0,1-1,1Z',
    },
  },
]

export const define = {
  iconName,
  icon: {
    width,
    height,
    color,
    children,
  },
}

export const measuresRestroom = define
